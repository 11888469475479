import React from 'react';
import axios from 'axios';
import Link from 'next/link';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import classNames from 'classnames';
import {withRouter} from 'next/router';

import {getRouteUrl} from '@utils/functions';
import styles from '@styles/common/Footer.module.scss';

// import Newsletter from './Newsletter';
import CountrySelection from './CountrySelection';

const BannerSlider = dynamic(() => import('@components/bannerSlider/index'));
class Footer extends React.PureComponent {
	state = {
		pageUrl: '',
		ipAddress: '',
		country: '',
	};
	async componentDidMount() {
		this.setPageUrl();
	}
	async componentDidUpdate(prevProps) {
		if (this.props.router.asPath !== prevProps.router.asPath) {
			this.setPageUrl();
		}
	}

	getIPInfo = async () => {
		try {
			const res = await axios.get(
				'https://geolocation-db.com/json/' + process.env.MIX_IP_ADDRESS_KEY,
			);
			return res ? res.data : {};
		} catch (err) {
			return '';
		}
	};

	setPageUrl = async () => {
		const pageUrl = getRouteUrl(this.props.router.asPath);
		const info = await this.getIPInfo();
		this.setState({
			pageUrl,
			ipAddress: info?.IPv4,
			country: info.country_code || 'AU',
		});
	};

	render() {
		return (
			<>
				{this.state.pageUrl && this.state.pageUrl === 'all-stores' && (
					<BannerSlider types={'footer'}></BannerSlider>
				)}
				<footer className={styles.footer}>
					<div className={styles.container}>
						{/* <Newsletter styles={styles} ipAddress={this.state.ipAddress} /> */}
						<div className={styles.ftMedial}>
							<div className='row'>
								<div className='col-12 col-md-3'>
									<div className={styles.footerAboutUs}>
										<div className={styles.ftLogo}>
											<Image
												alt='Fishing-in-Godzone'
												src='/img/logo_dark.png'
												height='77'
												width='225'
												objectFit='cover'
											/>
										</div>
										{/* <p>
											By making your everyday purchases at Better That, you can
											make a positive impact around the world. Better That is
											{` Australia's`} new socially conscious shopping
											destination where we make a donation to a good cause every
											time you shop. It costs you no more to shop at Better
											That, because all our products are at all our{' '}
											{`retailers' `}
											best prices and we make every donation out of our pocket,
											not yours. Do better every time you shop at Better That.
										</p> */}
										{/*<div
											className={classNames(
												styles.footerLinks,
												styles.socialLinks,
											)}
											>
											<h5>
												Stay Connected
												 <span>.</span>
											</h5>
											<ul className='d-flex'>
												<li>
													<Link href='https://www.facebook.com/shopbetterthat/?ref=aymt_homepage_panel&eid=ARCS2dVNbPHPcEQmlnzeGFwPclgfpkivg5y2RJPDVFXinaF6aG5GcugGTg_jwqSzfppuh1nlhsmHisWQ'>
														<a target='_blank'>
															<i className='fab fa-facebook-f'></i>
														</a>
													</Link>
												</li>
												<li>
													<Link href='https://www.instagram.com/better.that/?hl=en'>
														<a target='_blank'>
															<i className='fab fa-instagram'></i>
														</a>
													</Link>
												</li>
											</ul>
										</div> */}
									</div>
								</div>
								<div className='col-6 col-md-3'>
									<div className={styles.footerLinks}>
										<h5>
											Join us
											{/* <span>.</span> */}
										</h5>
										<ul>
											<li>
												<Link href='/sell'>Sell with us</Link>
											</li>
											<li>
												<Link href='/give'>Nominate a good cause</Link>
											</li>
											{/* <li>
												<Link href='/blog'>Blog</Link>
											</li> */}
										</ul>
									</div>
								</div>
								<div className='col-6 col-md-3'>
									<div className={styles.footerLinks}>
										<h5>
											Useful Links
											{/* <span>.</span> */}
										</h5>
										<ul>
											<li>
												<Link href='/good-causes'>Good Causes</Link>
											</li>
											<li>
												<Link href='/all-stores'>Stores</Link>
											</li>
											<li>
												<Link href='/brands/women'>Brands</Link>
											</li>
											<li>
												<Link href='/drop-auctions'>Drop Auctions</Link>
											</li>
											{/* <li>
												<Link href='/good-causes'>
													Price Promise and Payments
												</Link>
											</li> */}
											<li>
												<Link href='/returns'>Returns</Link>
											</li>
											<li>
												<Link href='/size-guide'>Size Guide</Link>
											</li>
											<li>
												<Link href='/pdf/UCBT_Buyer_Terms_of_Use.pdf'>
													<a target='_blank'>Terms Of Use</a>
												</Link>
											</li>
											<li>
												<Link href='/privacy-policy'>Privacy Policy</Link>
											</li>
										</ul>
									</div>
								</div>
								<div className={`${styles.lastServiceBox} col-6 col-md-3`}>
									<div className={styles.footerLinks}>
										<h5>
											Service
											{/* <span>.</span> */}
										</h5>
										<ul>
											{/* <li>
												<Link href='/about-us'>About us</Link>
											</li> */}
											<li>
												<Link href='/faq'>FAQ</Link>
											</li>
											<li>
												<Link href='/contact-us'>Contact</Link>
											</li>
										</ul>
									</div>
									<div className={styles.countrySelectionBox}>
										<CountrySelection />
									</div>
								</div>
							</div>
						</div>
						<div
							className={classNames(
								styles.bottomFooter,
								'd-md-flex align-items-center justify-content-between',
							)}
						>
							{/* <p>
								&copy; {new Date().getFullYear()} Better That (Betterthat Pty
								Ltd.)
							</p> */}
							<p>
							&copy; {new Date().getFullYear()} Fishing in Goodzone - Powered by Better That Connect
							</p>
							<ul className='d-flex align-items-center'>
								<li>
									<i className='fab fa-cc-visa'></i>
								</li>
								<li>
									<i className='fab fa-cc-mastercard'></i>
								</li>
							</ul>
						</div>
					</div>
				</footer>
			</>
		);
	}
}

export default withRouter(Footer);
